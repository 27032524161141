<!--
 * @Author: 智客云网络科技
 * @Date: 2021-12-23 23:20:47
 * @LastEditors: 阡陌OvO
 * @LastEditTime: 2022-02-03 23:25:37
 * @Description: 可发货包裹管理
 * @FilePath: \src\views\App\Package\DeliverablePackageManage.vue
-->

<template>
  <div class="vue-box">
    <!-- ------------- 检索 ------------- -->
    <div class="table-search-box">
      <div class="table-search-title">检索</div>
      <div class="table-search-content">
        <div class="table-search-item">
          <label>组织筛选：</label>
          <organization-picker v-model="p.kor_id"></organization-picker>
        </div>

        <div class="table-search-btn">
          <el-button
            size="small"
            type="primary"
            icon="el-icon-search"
            @click="
              p.pageNo = 1;
              _Load();
            "
            >查询</el-button
          >
        </div>
      </div>
    </div>

    <!-- ------------- 快捷按钮 ------------- -->
    <div class="table-btn">
      <!-- <el-button
        size="small"
        type="primary"
        icon="el-icon-plus"
        @click="_OpenCreatePackageModel()"
        >创建新包裹</el-button
      > -->
      <el-button
        size="small"
        type="warning"
        icon="el-icon-document-add"
        :disabled="create_cd_status"
        @click="_UnReadyDelivery()"
        >撤销可发货</el-button
      >
      <el-button
        size="small"
        type="info"
        icon="el-icon-refresh"
        @click="_Load()"
        >刷新</el-button
      >
    </div>

    <!-- ------------- 数据列表 ------------- -->
    <el-table
      class="data-table"
      ref="table"
      :data="TableData"
      size="mini"
      @row-click="_RowClick"
      @selection-change="_WatchRowSelect"
      stripe
      border
      row-key="id"
    >
      <!-- 多选 -->
      <el-table-column type="selection" align="center" width="50px">
      </el-table-column>

      <!-- ID -->
      <el-table-column
        label="ID"
        prop="Aid"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 发货渠道 -->
      <el-table-column
        label="发货渠道"
        prop="kch_name"
        align="center"
        width="130px"
      ></el-table-column>

      <!-- 包裹RFID -->
      <el-table-column
        label="包裹RFID"
        prop="rfid"
        align="center"
        width="120px"
      ></el-table-column>

      <!-- 包裹批次号 -->
      <el-table-column
        label="批次号"
        prop="batch_id"
        align="center"
        width="80px"
      ></el-table-column>

      <!-- 客户昵称 -->
      <el-table-column
        label="客户昵称"
        prop="ku_name"
        show-overflow-tooltip
        align="center"
        width="100px"
      ></el-table-column>

      <!-- 物品明细 -->
      <el-table-column
        label="物品明细"
        show-overflow-tooltip
        align="left"
        :formatter="_ShowItems"
        width="300px"
      ></el-table-column>

      <!-- 纸箱型号 -->
      <el-table-column label="纸箱型号" align="center" width="130px">
        <template slot-scope="s">
          <el-tag size="small" effect="plain" v-if="s.row.box_size === 220001">
            一号箱[60cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220005"
          >
            二号箱[80cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220010"
          >
            三号箱[100cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220015"
          >
            四号箱[120cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220020"
          >
            五号箱[150cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220025"
          >
            六号箱[180cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220030"
          >
            七号箱[200cm]
          </el-tag>
          <el-tag
            size="small"
            effect="plain"
            v-else-if="s.row.box_size === 220000"
          >
            非标准箱[{{ s.row.size }}]
          </el-tag>
          <el-tag size="small" effect="plain" type="info" v-else>未知</el-tag>
        </template>
      </el-table-column>

      <!-- 重量 -->
      <el-table-column
        label="重量"
        prop="weight"
        align="center"
        width="50px"
      ></el-table-column>

      <!-- 申报金额 -->
      <el-table-column
        label="申报金额"
        prop="const"
        align="center"
        width="80px"
      >
        <template slot-scope="s">
          <span style="color: #67c23a" v-if="s.row.const < 120">
            ${{ s.row.const }}
          </span>
          <span
            style="color: #e6a23c; font-weight: 600"
            v-else-if="s.row.const >= 120 && s.row.const <= 150"
          >
            ${{ s.row.const }}
          </span>
          <span style="color: #f56c6c; font-weight: 600" v-else>
            ${{ s.row.const }}
          </span>
        </template>
      </el-table-column>

      <!-- 打包时间 -->
      <el-table-column
        label="打包时间"
        prop="add_time"
        align="center"
        width="150px"
      >
      </el-table-column>

      <!-- 备注 -->
      <el-table-column label="备注">
        <template slot-scope="s"
          ><span>{{ s.row.notes }}</span></template
        >
      </el-table-column>

      <!-- 操作 -->
      <el-table-column label="操作" align="center" fixed="right" width="200px">
        <template slot-scope="s">
          <el-button
            size="mini"
            type="primary"
            icon="el-icon-edit"
            @click.stop="_OpenUpdatePackageModel(s.row.kpa_id)"
            >修改</el-button
          >
          <el-button
            size="mini"
            type="danger"
            icon="el-icon-delete"
            @click.stop="_DelPackage(s.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- ------------- 分页 ------------- -->
    <div class="page-box">
      <el-pagination
        background
        layout="total, prev, pager, next, sizes, jumper"
        :current-page.sync="p.pageNo"
        :page-size.sync="p.pageSize"
        :total="p.dataCount"
        :page-sizes="[10, 50, 100, 150]"
        @current-change="_Load()"
        @size-change="_Load()"
      >
      </el-pagination>
    </div>

    <!-- 增改组件 -->
    <nu-package ref="nu-package"></nu-package>
  </div>
</template>

<script>
import NuPackage from "./NUPackage";
import {
  getDeliverablePackageManage,
  delPackage,
  unReadyDelivery,
} from "./api"; //页面专有接口
import OrganizationPicker from "@/components/organizationPicker";
export default {
  name: "PackageManage",
  components: {
    NuPackage,
    OrganizationPicker,
  },
  data() {
    return {
      p: {
        kor_id: "", //组织ID
        pageNo: 1, //当前页
        pageSize: 10, //分页行数
        dataCount: 0, //数据总行数
      },
      TableData: [], // 数据集合
      create_cd_status: true, //创建申报单按钮状态
    };
  },
  watch: {
    //监听组织ID变动加载数据
    "p.kor_id": {
      handler() {
        this._Load();
      },
    },
  },
  methods: {
    //加载表格数据
    _Load() {
      getDeliverablePackageManage({
        pageNo: this.p.pageNo,
        pageSize: this.p.pageSize,
        kor_id: this.p.kor_id,
      })
        .then((res) => {
          this.TableData = res.data.data;
          this.p.dataCount = res.data.dataCount;
        })
        .catch((err) => {
          this.TableData = [];
          if (err.code === 220016) {
            return this.$utils.msg(err.err_msg);
          }
          this.$utils.error(err.err_msg);
        });
    },

    //打开包裹修改组件
    _OpenUpdatePackageModel(id) {
      this.$refs["nu-package"].open(id);
    },

    //生成货品明细
    _ShowItems(row) {
      let rows = JSON.parse(row.items_list);
      var ai = 0;
      let items = "";
      for (var i = 0; i < rows.length; i++) {
        let item = rows[i];
        if (i < 5) {
          if (i > 0) {
            items += ", ";
          }
          items += item.name + " *" + item.amount;
        } else {
          ai = ai + item.amount; //计数
          if (i === rows.length - 1) {
            items += "及其他" + ai + "件物品";
          }
        }
      }
      return items;
    },

    //表格行被点击
    _RowClick(row) {
      //修改表格行状态为选中
      this.$refs.table.toggleRowSelection(row);
    },

    //监听表格行选择
    _WatchRowSelect(rows) {
      if (rows.length > 0) {
        //解锁申报按钮
        return (this.create_cd_status = false);
      }
      //锁定申报按钮
      this.create_cd_status = true;
    },

    //撤回可发货包裹
    _UnReadyDelivery() {
      const rows = this.$refs.table.selection;
      let package_list = []; //包裹ID列表
      if (rows.length < 1) {
        return this.$utils.msg("请选择要提交的包裹！");
      }

      for (let i = 0; i < rows.length; i++) {
        package_list.push(rows[i]["rfid"]);
      }

      unReadyDelivery({
        kpa_list: JSON.stringify(package_list),
      })
        .then((res) => {
          this.$utils.success(res.err_msg);
          this._Load(); //重载表格
        })
        .catch((err) => {
          this.$utils.error(err.err_msg);
        });
    },

    //删除
    _DelPackage(data) {
      var _vm = this;
      this.$utils.confirm(
        "确认删除包裹【" +
          data.rfid +
          '】吗？</br><b style="color:red">此操作将立即生效并且不可撤销!</b>',
        () => {
          delPackage({ kpa_id: data.kpa_id })
            .then((res) => {
              _vm.$utils.success(res.err_msg);
              _vm._Load(); //重载表格数据
            })
            .catch((err) => {
              _vm.$utils.error(err.err_msg);
            });
        }
      );
    },
  },
};
</script>
